@import 'styles/app.scss';

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: $body_font;
  font-weight: $body_font_weight;
  font-size: $body_font_size;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.clear {
  @extend %clear;
}