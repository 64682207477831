@import 'styles/app.scss';

.oa {
  height: 100%;
}

.oa-header {
  height: 70px;
  padding-left: 20px;

  line-height: 70px;
  font-size: $header_font_size;
  font-weight: $header_font_weight;
  background-color: $header_background_color;
  color: $light_text_color;
}

.oa-content {
  position: fixed;
  left: 0;
  right: 0;
  top: 70px;
  bottom: 0;
}

.oa-nav {
  float: left;
  width: 45%;
  height: 100%;
}

.oa-map {
  float: left;
  width: 55%;
  height: 100%;
}
