/* Fonts */
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700');

/* Screen Sizes */
$mobile_width: 700px;
$tablet_width: 1000px;

/* Colors */
$header_background_color: #3A3A3A;

$accent_color: #CE4B4B;

$light_text_color: #FFFFFF;

/* Text */
$body_font: 'Source Sans Pro', sans-serif;

$header_font_size: 28px;
$header_font_weight: 700;

$body_font_size: 16px;
$body_font_size-sm: 14px;
$body_font_weight: 600;

/* Sepcial Classes */
%clear {
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}