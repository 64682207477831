@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:600,700);
/* Fonts */
/* Screen Sizes */
/* Colors */
/* Text */
/* Sepcial Classes */
.clear:after {
  content: "";
  display: block;
  clear: both; }

html {
  height: 100%; }

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

#root {
  height: 100%; }

.deal_list {
  height: 100%;
  width: 100%; }
  .deal_list .current_day {
    padding: 60px;
    text-align: center; }

/* Fonts */
/* Screen Sizes */
/* Colors */
/* Text */
/* Sepcial Classes */
.oa {
  height: 100%; }

.oa-header {
  height: 70px;
  padding-left: 20px;
  line-height: 70px;
  font-size: 28px;
  font-weight: 700;
  background-color: #3A3A3A;
  color: #FFFFFF; }

.oa-content {
  position: fixed;
  left: 0;
  right: 0;
  top: 70px;
  bottom: 0; }

.oa-nav {
  float: left;
  width: 45%;
  height: 100%; }

.oa-map {
  float: left;
  width: 55%;
  height: 100%; }

